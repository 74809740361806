.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.Navigator {
  text-decoration: dotted;
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.menu a {
  /* padding: 10px 20px; */
  margin: 0 10px;
  /* background-color: #007BFF; */
  text-decoration: underline;
  color: black;
  transition: background-color 0.3s ease;
}

.menu a:hover {
  
}

/* Button in the header */
button {
  /* padding: 10px 20px; */
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: transparent;
  color: rgb(255, 255, 255);
  border: none;
  /* border-radius: 4px; */
  cursor: pointer;
  text-decoration: underline;
  
  
  /* transition: background-color 0.3s ease; */
}

button:hover {
  background-color: transparent;
}

/* Popup styles */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* ensure it's on top */
}

.popup-container ul {
  list-style-type: none;
  padding: 0;
}

.popup-container li {
  margin: 10px 0;
}

.popup-container h2 {
  color: aliceblue;
}

/* Popup content */
.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 70%; /* or whatever you prefer */
  max-width: 500px; /* or adjust this value */
}

.popup-content img {
  width: 50px; /* adjust this based on your images */
  height: 50px;
  margin-left: 10px;
}

/* Close button on popup */
.popup-container button {
  position: absolute;
  bottom: 20px;
}


