.reading-passage {
    font-family: 'Arial', sans-serif;
    display: flex;
    width: 100vw;
    height: 100vh;
}

.main-section {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.timer-container {
    display: flex;
    justify-content: center;
    align-items: center;
}


.answer-section {
    flex-basis: 10%; 
    border-left: 1px solid #ccc;
    padding: 20px;
    box-shadow: -2px 0px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;  
    /* max-width: 200px; */
}

.answer-section h4 {
    text-align: center;
    margin-bottom: 10px;
}

.answer-section input {
    display: block;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 100px;
    margin-left: 0px;
}

.main-section {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.audio-section {
    width: 50vw ;
}

.content-section {
    display: flex;
    gap: 20px;
    overflow: hidden;
    color: #333;  /* dark gray color */
    background-color: #ffffff;  /* white background */
}

.passage-section,
.questions-section {
    flex: 1;
    overflow-y: auto;
    height: 73vh; 
    padding: 20px;
    margin-top: 10px;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif; /* Use Arial font */
    font-size: 16px; /* Adjust as per your requirement */
    line-height: 1.6; /* Providing spacing between lines for better readability */
}

.passage-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.results-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 72%; /* Adjusted to be 20% smaller than the original 90% */
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.results-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns now */
    gap: 10px;
    width: 100%;
    margin-bottom: 20px;
}

.result-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px; /* Given a specific height to fit all results without scrolling */
    border: 1px solid #ccc;
    border-radius: 4px;
}

.results-popup button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* Context Menu styles */

div[style*="absolute"] {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    z-index: 1002; /* Ensure it appears above everything */
}

div[style*="absolute"] button {
    display: block;
    background-color: #f5f5f5;
    border: none;
    padding: 8px 12px;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

div[style*="absolute"] button:hover {
    background-color: #e9e9e9;
}


.passage-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

.passage-section div {
    text-align: left;
}

.questions-section div {
    text-align: left;
}

.fullscreen-button {
    position: absolute;
    top: 10px;
    right: 200px;
    z-index: 1000; /* ensures it's above other content */
    padding: 5px 10px;
    cursor: pointer;
    font-size: smaller;
    color: grey;
}

.back-button {
    cursor: pointer;
    font-size: smaller;
    color: grey;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 10px;
}

/* Base button style */
.audio-control-btn {
    padding: 8px 5px;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 0px; 
}

/* Forward button style */
.audio-control-btn.forward {
    color: grey;
    position: absolute;
    top: 50px;
    left: 350px;
    z-index: 1000;
    font-size: 12px;
    text-decoration: none;
    font-weight:500
}

.audio-control-btn.forward:hover {

}

/* Backward button style */
.audio-control-btn.backward {
    color: grey;
    position: absolute;
    top: 50px;
    left: 320px;
    z-index: 1000;
    font-size: 12px;
    text-decoration: none;
    font-weight: 500;
}

.audio-control-btn.backward:hover {

}
