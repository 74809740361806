.writing-passage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.input-field, .email-input {
    width: 190%; /* Increased from 80% to 90% */
    margin-bottom: 10px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.email-input {
    height: 40px; /* Adjust height as needed */
}

.submit-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.submit-button:hover {
    background-color: #45a049;
}

.response-container {
    width: 80%;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    padding: 10px;
}

.back-button {
    cursor: pointer;
    color: #0066cc;
    text-decoration: underline;
}

.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 20px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.status-success {
    color: green;
    font-weight: bold; /* Makes text bold */
    font-size: 1.2em; /* Increases the font size */
}

.status-error {
    color: red;
     font-weight: bold; /* Makes text bold */
    font-size: 1.2em; /* Increases the font size */
}

.error-message {
    color: red;
    font-size: 0.8em;
    margin: 5px 0;
}